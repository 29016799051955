import { BinnacleTable } from 'components/services/binnacle/table/BinnacleTable'
import { HttpPetitionTrackingRepository } from 'modules/contractedService/infrastructure/management/tracking/HttpPetitionTrackingRepository'
import { FC, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { UfinetBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'

type BinnacleEventPageProps = {
	isHistory: boolean
}
export const BinnacleEventPage: FC<BinnacleEventPageProps> = ({ isHistory }) => {
	const { serviceId = '' } = useParams()
	const authData = useContext(AuthContext)
	const petitionTrackingRepository = useMemo(() => HttpPetitionTrackingRepository(authData), [authData])

	return (
		<UfinetBox>
			<BinnacleTable
				serviceId={serviceId}
				petitionTrackingRepository={petitionTrackingRepository}
				isHistory={isHistory}
			/>
		</UfinetBox>
	)
}
