import dayjs from 'dayjs'
import { Binnacle } from 'modules/contractedService/domain/management/tracking/entities/PetitionTrackingBinnacle'
import { ColData } from 'ufinet-web-components'
import { noContentPlaceholder, TranslatorFunction } from 'ufinet-web-functions'
import { toast } from 'react-toastify'

function processBase64Response(base64Response: string, filename: string, translator: TranslatorFunction) {
	try {
		if (base64Response) {
			if (!isValidBase64(base64Response)) {
				throw new Error("El campo 'resultado' no contiene una cadena válida en Base64.")
			}
			const fileBytes = base64ToUint8Array(base64Response)
			downloadFileFromUint8Array(fileBytes, filename)
		} else {
			toast.error(translator('BINNACLE.FILE_DOWNLOAD_ERROR'))
		}
	} catch (error) {
		toast.error(translator('BINNACLE.FILE_DOWNLOAD_ERROR'))
	}
}

function isValidBase64(base64: string): boolean {
	const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/
	return base64Regex.test(base64)
}

function base64ToUint8Array(base64: string): Uint8Array {
	const binaryString = atob(base64)
	const length = binaryString.length
	const bytes = new Uint8Array(length)
	for (let i = 0; i < length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	return bytes
}

function downloadFileFromUint8Array(data: Uint8Array, filename: string): void {
	const extension = getFileExtension(data)
	const fullFilename = `${filename}.${extension}`
	const blob = new Blob([data], { type: 'application/octet-stream' })
	const url = URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.href = url
	a.download = fullFilename
	a.click()

	URL.revokeObjectURL(url)
}

function getFileExtension(uint8Array: Uint8Array): string {
	const magicNumbersMap: { [key: string]: string } = {
		'89504E47': 'png',
		FFD8FFE0: 'jpg',
		FFD8FFE1: 'jpg',
		FFD8FFE2: 'jpg',
		'47494638': 'gif',
		'25504446': 'pdf',
		'504B0304': 'zip',
		'7B5C7274': 'rtf',
		'3C3F786D': 'xml',
		'49492A00': 'tif',
		'4D4D002A': 'tif',
		D0CF11E0: 'doc',
	}

	const hex = Array.from(uint8Array.slice(0, 4))
		.map((byte) => byte.toString(16).padStart(2, '0'))
		.join('')
		.toUpperCase()

	let extension = magicNumbersMap[hex] || 'bin'

	if (extension === 'zip') {
		const content = new TextDecoder().decode(uint8Array)
		if (content.includes('[Content_Types].xml')) {
			if (content.includes('word/')) {
				extension = 'docx'
			} else if (content.includes('xl/')) {
				extension = 'xlsx'
			} else if (content.includes('ppt/')) {
				extension = 'pptx'
			}
		}
	}

	return extension
}

export const binnacleTableCols = (translator: TranslatorFunction): ColData<Binnacle>[] => [
	{
		field: 'eventType',
		header: 'BINNACLE.EVENT_TYPE',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
		body: (row: Binnacle) =>
			row?.eventType ? translator(`BINNACLE.EVENT_TYPE.${row.eventType}`) : noContentPlaceholder,
	},
	{
		field: 'date',
		header: 'BINNACLE.EVENT_DATE',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
		body: (row: Binnacle) => dayjs(row.date).format('DD/MM/YYYY HH:mm:ss') || noContentPlaceholder,
	},
	{
		field: 'createdBy',
		header: 'BINNACLE.EVENT_USER',
		width: '20%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
	},
	{
		field: 'observation',
		header: 'BINNACLE.EVENT_OBSERVATION',
		width: '40%',
		nonFilterable: true,
		nonSortable: false,
		nonSearchable: false,
		body: (row: Binnacle) => (
			<span>
				{row.observation}{' '}
				{row.attachment && row.attachmentUrl && (
					<button
						onClick={() => {
							try {
								const filename = row.attachmentUrl.split('/').pop()?.split('.')[0] || 'attachment'
								processBase64Response(row.attachment, filename, translator)
							} catch (error) {
								toast.error(translator('BINNACLE.FILE_DOWNLOAD_ERROR'))
							}
						}}
						style={{
							background: 'none',
							color: 'blue',
							border: 'none',
							textDecoration: 'underline',
							cursor: 'pointer',
							padding: '0',
							fontSize: 'inherit',
						}}
					>
						{translator('BINNACLE.SEE_ATTACHMENT')}
					</button>
				)}
			</span>
		),
	},
]
