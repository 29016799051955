import { ContractedService } from 'modules/contractedService/domain/ContractedService'
import { ContractedServiceType } from 'modules/contractedService/domain/ContractedServiceType'
import { ContractedServiceState } from 'modules/contractedService/domain/state/ContractedServiceState'
import { ColData, TooltipButton } from 'ufinet-web-components'
import { TranslatorFunction, noContentPlaceholder } from 'ufinet-web-functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { PATH_EVENTS } from 'routing/protected/PrivateRoutes'
import { NavigateFunction } from 'react-router-dom'

const contractServiceStateExcludedValues = [
	ContractedServiceState.INACTIVO,
	ContractedServiceState.CANCELADO,
	ContractedServiceState.ANULADO,
	ContractedServiceState.PENDIENTE_CANCELACION,
	ContractedServiceState.PENDIENTE_SUSPENSION,
	ContractedServiceState.PENDIENTE_REACTIVACION,
	ContractedServiceState.NO_USAR_INACTIVO,
]

const ellipsisStyle: React.CSSProperties = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	maxWidth: '120px',
	display: 'block',
}
const tooltipButtonStyle: React.CSSProperties = {
	background: 'none',
	border: 'none',
	padding: 0,
	margin: 0,
	cursor: 'default',
	boxShadow: 'none',
	color: '#495057',
}
export const colsContractedServicesExternal = (
	translate: TranslatorFunction,
	navigate: NavigateFunction
): ColData[] => [
	{
		field: 'administrativeCode',
		header: 'CONTRACT.SERVICE.ADMINISTRATIVE_CODE',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) => {
			return row.administrativeCode?.trim() || '-'
		},
	},
	{
		field: 'description',
		header: 'CONTRACT.SERVICE.DESCRIPTION',
		nonFilterable: true,
		nonSortable: true,
		body: (row: ContractedService) => row.serviceName?.trim() || '-',
	},
	{
		field: 'typeOfService',
		header: 'CONTRACT.SERVICE.TYPE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceType)
			.filter((type) => isNaN(Number(type)))
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.TYPE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.typeOfService ? noContentPlaceholder : translate(`CONTRACT.SERVICE.TYPE.${row.typeOfService.value}`),
	},
	{
		field: 'location',
		header: 'CONTRACT.SERVICE.LOCATION',
		nonFilterable: true,
		nonSortable: true,
		body: (data: ContractedService) => {
			const origin = data.originSummary?.trim() || ''
			const destination = data.destinationSummary?.trim() || ''

			const formattedOrigin = origin ? `A: ${origin}` : ''
			const formattedDestination = destination ? `Z: ${destination}` : ''

			return (
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					<TooltipButton
						buttonProps={{
							style: tooltipButtonStyle,
							content: (
								<div style={{ maxWidth: '120px', flexGrow: 1 }}>
									{formattedOrigin && <span style={ellipsisStyle}>{formattedOrigin}</span>}
									{formattedDestination && <span style={ellipsisStyle}>{formattedDestination}</span>}
								</div>
							),
						}}
					>
						<div>
							{formattedOrigin && <div>{formattedOrigin}</div>}
							{formattedDestination && <div>{formattedDestination}</div>}
						</div>
					</TooltipButton>
				</div>
			)
		},
	},
	{
		field: 'state',
		header: 'CONTRACT.SERVICE.STATE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceState)
			.filter(
				(type) =>
					isNaN(Number(type)) &&
					!contractServiceStateExcludedValues.includes(
						ContractedServiceState[type as keyof typeof ContractedServiceState]
					)
			)
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.STATE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.state ? noContentPlaceholder : translate(`CONTRACT.SERVICE.STATE.${row.state.value}`),
	},
	{
		field: 'events',
		header: 'CONTRACT.SERVICE.EVENTS',
		width: '5%',
		contentDisplayFormat: 'center',
		nonFilterable: true,
		nonSortable: true,
		nonSearchable: true,
		body: (row: ContractedService) => (
			<TooltipButton
				buttonProps={{
					className: 'table-action-button',
					content: (
						<FontAwesomeIcon
							className="fa-solid"
							size="lg"
							icon={faBook}
							color="#0065a4"
							onClick={() => navigate(`${row.contractedServiceOriginalId}/${PATH_EVENTS}`)}
						/>
					),
				}}
			>
				{translate('CONTRACT.SERVICE.EVENTS.DETAILS')}
			</TooltipButton>
		),
	},
]

export const colsContractedServicesInternal = (
	translate: TranslatorFunction,
	navigate: NavigateFunction
): ColData[] => [
	{
		field: 'administrativeCode',
		header: 'CONTRACT.SERVICE.ADMINISTRATIVE_CODE',
		nonFilterable: true,
		nonSearchable: false,
		width: '150px',
		body: (row: ContractedService) => {
			return row.administrativeCode?.trim() || noContentPlaceholder
		},
	},
	{
		field: 'description',
		header: 'CONTRACT.SERVICE.DESCRIPTION',
		nonFilterable: true,
		nonSortable: true,
		body: (row: ContractedService) => row.serviceName?.trim() || noContentPlaceholder,
	},
	{
		field: 'typeOfService',
		header: 'CONTRACT.SERVICE.TYPE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceType)
			.filter((type) => isNaN(Number(type)))
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.TYPE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.typeOfService ? noContentPlaceholder : translate(`CONTRACT.SERVICE.TYPE.${row.typeOfService.value}`),
	},
	{
		field: 'location',
		header: 'CONTRACT.SERVICE.LOCATION',
		nonFilterable: true,
		nonSortable: true,
		body: (data: ContractedService) => {
			const origin = data.originSummary?.trim() || ''
			const destination = data.destinationSummary?.trim() || ''

			const formattedOrigin = origin ? `A: ${origin}` : ''
			const formattedDestination = destination ? `Z: ${destination}` : ''

			return (
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					<TooltipButton
						buttonProps={{
							style: tooltipButtonStyle,
							content: (
								<div style={{ maxWidth: '120px', flexGrow: 1 }}>
									{formattedOrigin && <span style={ellipsisStyle}>{formattedOrigin}</span>}
									{formattedDestination && <span style={ellipsisStyle}>{formattedDestination}</span>}
								</div>
							),
						}}
					>
						<div>
							{formattedOrigin && <div>{formattedOrigin}</div>}
							{formattedDestination && <div>{formattedDestination}</div>}
						</div>
					</TooltipButton>
				</div>
			)
		},
	},
	{
		field: 'state',
		header: 'CONTRACT.SERVICE.STATE',
		nonFilterable: false,
		nonSearchable: true,
		nonSortable: true,
		filterOptions: Object.keys(ContractedServiceState)
			.filter(
				(type) =>
					isNaN(Number(type)) &&
					!contractServiceStateExcludedValues.includes(
						ContractedServiceState[type as keyof typeof ContractedServiceState]
					)
			)
			.map((type) => ({ label: translate(`CONTRACT.SERVICE.STATE.${type}`), value: type })),
		body: (row: ContractedService) =>
			!row.state ? noContentPlaceholder : translate(`CONTRACT.SERVICE.STATE.${row.state.value}`),
	},
	{
		field: 'events',
		header: 'CONTRACT.SERVICE.EVENTS',
		width: '5%',
		contentDisplayFormat: 'center',
		nonFilterable: true,
		nonSortable: true,
		nonSearchable: true,
		body: (row: ContractedService) => (
			<TooltipButton
				buttonProps={{
					className: 'table-action-button',
					content: (
						<FontAwesomeIcon
							className="fa-solid"
							size="lg"
							icon={faBook}
							color="#0065a4"
							onClick={() => navigate(`${row.contractedServiceOriginalId}/${PATH_EVENTS}`)}
						/>
					),
				}}
			>
				{translate('CONTRACT.SERVICE.EVENTS.DETAILS')}
			</TooltipButton>
		),
	},
]
