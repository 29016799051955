import { binnacleTableCols } from 'components/services/management/tracking/table/binnacleTableCols'
import {
	usePetitionTrackingFindBinnacleQuery,
	usePetitionTrackingFindHistoryBinnacleQuery,
} from 'modules/contractedService/application/management/tracking/find/PetitionTrackingFindQueries'
import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { FC } from 'react'
import { Table, UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'

type BinnacleTableProps = {
	serviceId: string
	petitionTrackingRepository: PetitionTrackingRepository
	isHistory: boolean
}

export const BinnacleTable: FC<BinnacleTableProps> = ({ serviceId, petitionTrackingRepository, isHistory }) => {
	const translate = useTranslator()

	const historyBinnacleQuery = usePetitionTrackingFindHistoryBinnacleQuery(petitionTrackingRepository, serviceId, {
		enabled: isHistory && Boolean(serviceId),
	})

	const binnacleQuery = usePetitionTrackingFindBinnacleQuery(petitionTrackingRepository, serviceId, {
		enabled: !isHistory && Boolean(serviceId),
	})

	const { data = { binnacle: [] }, isLoading: isLoadingEvents } = isHistory ? historyBinnacleQuery : binnacleQuery

	return (
		<UfinetSectionBox className="position-relative">
			<h2 className="fs-3 fw-bolder text-dark">{translate('BINNACLE.TITLE')}</h2>
			<Table
				content={data?.binnacle}
				cols={binnacleTableCols(translate)}
				emptyMessage={isLoadingEvents ? translate('LOADING_DOTS') : undefined}
			/>
		</UfinetSectionBox>
	)
}
